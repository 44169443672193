import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './index.css';

export class Image extends Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    id: PropTypes.string,
    className: PropTypes.string,
    border: PropTypes.bool,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    style: PropTypes.object,
    alt: PropTypes.string,
  };

  static defaultProps = {
    border: false,
    width: '100%',
  };

  render() {
    const { src, id, border, height, width, style, alt } = this.props;

    const imageStyles = {
      ...style,
    };

    return (
      <div className={`component image ${border ? 'has-border' : ''}`} id={id}>
        <img
          src={src}
          height={height}
          width={width}
          style={imageStyles}
          alt={alt}
        />
      </div>
    );
  }
}

export default Image;
